import SecondaryMenu from "../../components/layout/SecondaryMenu";
import SectionFields from "../../components/form/molecules/SectionFields";
import Modal from "../../components/Modal";
import { MotifButton } from "@ey-xd/motif-react";
import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/pages/new-activity.scss";
import { handleFileUpload } from "../../services/UploadImageBlob";
import {
  deleteLanding,
  fetchCategoriesData,
  fetchCountriesData,
  fetchOrganizationsData,
  postLandingsData,
} from "../../services/api";
import {
  galleryUploadedFilter,
  imageUploadedFilter,
  deleteEmptyFields,
  extractUrlBeforeQueryString,
} from "../../globals";
import LabelsContext from "../../context/LabelsContext";
import InvalidModal from "../../components/InvalidModal";
import DeleteModal from "../../components/DeleteModal";
const Landing = () => {
  const [showModal, setShowModal] = useState(false);
  const [categoryOptions, setCategories] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [organizationsName, setOrganizations] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [actionType, setActionType] = useState("");
  const labels = useContext(LabelsContext);
  const location = useLocation();
  const landingsData = location.state?.landingData || null;
  const [formData, setFormData] = useState({
    categoryName: landingsData.categoryName,
    description: landingsData.description,
    organizationName: landingsData.organizationName,
    gallery: landingsData.gallery,
    title: landingsData.title,
    image: landingsData.image,
    landingId: landingsData.landingId,
    country: landingsData.country,
    url: landingsData.url || "",
    urlLink: landingsData.urlLink || "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrganizationsData(false).then((data) => {
      const organizations = Array.isArray(data) ? data : [];
      setOrganizations(organizations);
    });
    fetchCategoriesData().then((data) => {
      const categories = Array.isArray(data.categories)
        ? data.categories.map((category) => ({
            value: category.id,
            label: category.name,
          }))
        : [];
      setCategories(categories);
    });
    fetchCountriesData().then((data) => {
      const countries = Array.isArray(data)
        ? data.map((country) => ({
            value: country.countryName,
            label: country.countryName,
          }))
        : [];
      setCountryOptions(countries);
    });
  }, []);
  //const handleFieldChange = (field, value) => {
  //setFormData((prev) => ({ ...prev, [field]: value }));
  //};
  const [fileQueue, setFileQueue] = useState([]);
  const [fileQueueGallery, setFileQueueGallery] = useState(
    landingsData.gallery
  );
  const handleFormSubmit = async (drafted) => {
    setisFormSubmited(true);
    if (isFormValid()) {
      setIsDisabledButton(true);
      const landingId = formData.landingId;
      const submitData = {
        ...formData,
        landingId,
        image:
          fileQueue.length > 0
            ? imageUploadedFilter(fileQueue, landingId, "landing")
            : formData.image
            ? {
                ...formData.image,
                url: extractUrlBeforeQueryString(formData.image, "url"),
              }
            : null,
        video: formData.url
          ? { url: formData.url, mimeType: "image/jpeg" }
          : null,
        gallery: galleryUploadedFilter(fileQueueGallery, landingId),
        url: formData.urlLink,
        isDraft: drafted,
      };
      handleFileUpload(fileQueue, submitData.landingId, "landing");
      handleFileUpload(fileQueueGallery, submitData.landingId, "landing");
      await postLandingsData(deleteEmptyFields(submitData));
      navigate("/organizations/all-landings");
      setIsDisabledButton(false);
    } else {
      setShowInvalidModal(true);
      setIsDisabledButton(false);
    }
  };
  const isFormValid = () => {
    const requiredFields = ["title", "image", "description", "country"];
    const newMissingFields = [];
    requiredFields.forEach((field) => {
      if (
        !formData[field] ||
        (field === "description" && formData[field] === "<p><br></p>")
      ) {
        newMissingFields.push(field);
      }
    });
    setMissingFields(newMissingFields);
    return newMissingFields.length === 0;
  };
  const [isFormSubmited, setisFormSubmited] = useState(false);
  const navigate = useNavigate();
  const openWarningModal = () => {
    setActionType("save");
    setShowModal(true);
  };
  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowModal(false);
    setShowInvalidModal(false);
    setShowDeleteModal(false);
  };
  const handleDiscardChanges = () => {
    window.scrollTo(0, 0);
    setShowModal(false);
    navigate("/");
  };
  const handleNavigateHome = () => {
    navigate("/organizations/all-landings");
  };
  const deleteLandingId = async (landingId) => {
    setIsDisabledButton(true);
    const response = await deleteLanding(landingId);
    if (response.status === 204) {
      navigate("/organizations/all-landings");
      setIsDisabledButton(true);
    } else {
      setIsDisabledButton(false);
    }
  };

  const handleCancelClick = () => {
    setActionType("cancel");
    setShowModal(true);
  };
  const openDeteteModal = () => {
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
    setIsEditing(!isEditing);
  };

  const formFieldConfig = {
    organizationName: { required: false },
    image: { required: true },
  };

  return (
    <>
      <SecondaryMenu
        title={labels.console_edit_landing}
        onNavigate={handleNavigateHome}
        editing={isEditing}
        onEditClick={handleEditClick}
        sendFormDraft={() => handleFormSubmit(true)}
        pageType="landings"
      />
      <section className="motif-col-lg-12 section-page">
        <div
          className={
            fileQueue[0] || formData.image.url ? "container-test" : null
          }
        >
          <div>
            <SectionFields
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              setFileQueue={setFileQueue}
              pastGallery={landingsData.gallery}
              setFileQueueGallery={setFileQueueGallery}
              organizationsName={organizationsName}
              categoryOptions={categoryOptions}
              countryOptions={countryOptions}
              isDisabled={isDisabled}
              isEditing={isEditing}
              imageLabel={labels.console_image}
              descriptionLabel={labels.console_description}
              formFieldConfig={formFieldConfig}
              mode={"edit"}
              fieldVisibility={{
                categoryName: true,
                shortDescription: true,
                description: true,
                organizationName: true,
                title: true,
                gallery: true,
                image: true,
                country: true,
                url: true,
                urlLink: true,
              }}
            />
          </div>
          {fileQueue[0] ? (
            <img src={fileQueue[0]?.previewUrl} alt="" className="image-test" />
          ) : (
            <img src={formData.image.url} alt="" className="image-test" />
          )}
        </div>
        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={openDeteteModal}
            className="activity-buttons-third"
            disabled={isDisabled}
          >
            {labels.console_delete}
          </MotifButton>
          <MotifButton
            variant="secondary"
            onClick={handleCancelClick}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={openWarningModal}
            className="activity-buttons-primary"
            disabled={isDisabled}
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>
      <section className="motif-col-lg-5"></section>
      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={
          actionType === "save"
            ? labels.console_changes_saved
            : labels.console_unsavedChanges
        }
        body={
          actionType === "save"
            ? labels.console_confirm_publish_landing
            : labels.console_leavePage
        }
        primaryButtonText={
          actionType === "save"
            ? labels.console_publish
            : labels.console_keepEditing
        }
        onPrimaryButtonClick={
          actionType === "save"
            ? () => handleFormSubmit(false)
            : closeWarningModal
        }
        secondaryButtonText={
          actionType === "save"
            ? labels.console_cancel
            : labels.console_discardChanges
        }
        onSecondaryButtonClick={handleDiscardChanges}
        disabled={isDisabledButton}
      />
      <DeleteModal
        show={showDeleteModal}
        onClose={closeDeleteModal}
        title={labels.console_delete_landing}
        body={labels.console_delete_landing_confirmation}
        primaryButtonText={labels.console_button_delete}
        onPrimaryButtonClick={() => deleteLandingId(formData.landingId)}
        secondaryButtonText={labels.console_button_cancel}
        onSecondaryButtonClick={closeDeleteModal}
        disabled={isDisabledButton}
      />
      <InvalidModal
        show={showInvalidModal}
        onClose={closeInvalidModal}
        title={labels.console_title_invalidForm}
        body={labels.console_body_invalidForm}
        primaryButtonText={labels.console_close}
        onPrimaryButtonClick={closeInvalidModal}
        invalidFieldsMessage={missingFields.join(", ")}
      />
    </>
  );
};
export default Landing;
